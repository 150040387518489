export default {
    init() {
        // JavaScript to be fired on the home page

        $('.lwa-links-remember').on('click', function () {
            $('.lwa-form').css('opacity', '0');
            setTimeout(function () {
                $('.lwa-remember').css({
                    'opacity':'1',
                    'pointer-events': 'all',
                })
            }, 200)
        })

        $('.lwa-links-remember-cancel').on('click', function () {
            setTimeout(function () {
                $('.lwa-form').css('opacity', '1');
            }, 200)

            $('.lwa-remember').css({
                'opacity':'0',
                'pointer-events': 'none',
            })
        })

        $('.sidebar__login__form .gfield_checkbox input').prop('checked', true);
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
