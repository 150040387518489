
jQuery(document).ready(function($) {
    $('.form__profiel .readonly .gfield_checkbox li input').each(function () {
        if(!$(this).is(':checked')) {
            $(this).parent().remove()
        }
    });

    $('.form__profiel .gfield .ginput_container_password .ginput_left input, .form__profiel .gfield .ginput_container_password .ginput_right input').keypress(function () {
        $('.form__profiel .gfield_password_strength').show();
    })
});
