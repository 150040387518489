// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

import 'custom-event-polyfill/polyfill';
import './routes/modernizr';
import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'slick-carousel/slick/slick.min';
import 'jquery-match-height/jquery.matchHeight';
import 'theia-sticky-sidebar/dist/ResizeSensor.min';
import 'theia-sticky-sidebar/dist/theia-sticky-sidebar.min';
import 'infinite-scroll/dist/infinite-scroll.pkgd.min';
import 'canvas-confetti/dist/confetti.browser'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import like from './routes/like';
import banner from './routes/banner';
import menu from './routes/menu';
import home from './routes/home';
import templateContact from './routes/templateContact';
import templateAfdelingen from './routes/templateAfdelingen';
import templateDocumenten from './routes/templateAfdelingen';
import templateSmoelenboek from './routes/templateSmoelenboek';
import templateProfile from './routes/templateProfile';
import templateProductieDashboard from './routes/templateProductieDashboard';
import aboutUs from './routes/about';

// Template - Team
// import templateTeam from './routes/templateTeam';

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    like,
    banner,
    menu,
    // Home page
    home,
    // About Us page, note the change from about-us to aboutUs.
    aboutUs,
    templateContact,
    templateAfdelingen,
    templateSmoelenboek,
    templateDocumenten,
    templateProfile,
    templateProductieDashboard,
    // Templates
    // templateTeam,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
