/* eslint-disable */
export default {
    init() {
        // JavaScript to be fired on all pages

        $('.page__pages__fancybox').fancybox({
            // Options will go here
            buttons: [
                "zoom",
                "download",
                "close"
            ]
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

