jQuery(document).ready(function($) {

    $(document).on('click', '.archive__item__like', function () {

        let $postid = $(this).data('postid');
        let $userid = $(this).data('userid');
        let $current = $(this);

        $current.css('pointer-events', 'none');

        setTimeout(function (){
            $current.css('pointer-events', 'all');
        }, 1000);

        if ($(this).find('.archive__item__like__check').hasClass('yes')) {
            deleteLike($postid, $userid, $current);
        } else {
            createLike($postid, $userid, $current);
        }
    })

    /* eslint-disable */

    function createLike(postid, userid, current) {

        // Change like class status
        current.find('.archive__item__like__check').removeClass('no')
        current.find('.archive__item__like__check').addClass('yes')

        // increase total + 1
        let $total = parseInt(current.find('.archive__item__like_total').text());
        $total++;
        current.find('.archive__item__like_total').text($total);


        $.ajax({
            beforeSend: (xhr) => {
                xhr.setRequestHeader('X-WP-Nonce', moveData.nonce);
            },
            url: moveData.root_url + '/wp-json/like/v1/manageLike',
            type: 'POST',
            data: {'postid': postid, 'userid': userid},
            success: () => {

            },
            error: (response) => {
                console.log(response);
            },
        });
    }

    function deleteLike(postid, userid, current) {

        // Change like class status
        current.find('.archive__item__like__check').removeClass('yes')
        current.find('.archive__item__like__check').addClass('no')

        // decrease total - 1
        let $total = parseInt(current.find('.archive__item__like_total').text());
        $total--;
        current.find('.archive__item__like_total').text($total);

        $.ajax({
            beforeSend: (xhr) => {
                xhr.setRequestHeader('X-WP-Nonce', moveData.nonce);
            },
            url: moveData.root_url + '/wp-json/like/v1/manageLike',
            type: 'DELETE',
            data: {'postid': postid, 'userid': userid},
            success: () => {

            },
            error: (response) => {
                console.log(response);
            },
        });
    }
    /* eslint-enable */
});
