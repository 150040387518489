/* eslint-disable */
export default {
    init() {
        // JavaScript to be fired on all pages

        $('.team__block--department .team__block__inner').on('click', function () {

            $('html, body').animate({
                scrollTop: $('#scroll__smoelenboek').offset().top - 50
            }, 1000)

            $('.team__block--department .team__block__inner').parent('.team__block--department').removeClass('active')
            $('.team__block--department .team__block__inner').parent('.team__block--department').addClass('inactive')


            $(this).parent('.team__block--department').removeClass('inactive')
            $(this).parent('.team__block--department').addClass('active')

            let $departmentID = $(this).data('department');

            $('.team_department__block').each(function () {
                let $departmentBlockID = $(this).data('department');
                if($departmentBlockID === $departmentID) {
                    $('.team_department__block').hide();
                    $(this).show();
                }
            })
        })


        $('.team_department__block').each(function () {
            let $department = $(this).data('department');

            let $array = [];

            // get unique values from array.
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }

            $(this).find('.team__block--employee').each(function () {
                // get all user data attr: user and push to array
                let $getUser = $(this).data('user');
                $array.push($getUser);
            })

            // run filter function to get only unique total
            let $total = $array.filter( onlyUnique )

            // add total users to location.
            $('.team__block--department .team__block__inner').each(function () {
                let $departmentInner = $(this).data('department');
                if ($departmentInner === $department) {
                    $(this).find('.team__block__count').html($total.length);
                }
            })

        })

        setTimeout(function(){
            $('.team__block__count').css('opacity', '1');
        }, 300);

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

